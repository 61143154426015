    import { Configuration, PublicClientApplication } from '@azure/msal-browser';

    const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '', // Use fallback if the variable is undefined
        authority: process.env.REACT_APP_MSAL_AUTHORITY || '',
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI || '',
    },
    cache: {
        cacheLocation: 'localStorage', // Store tokens in localStorage
        storeAuthStateInCookie: true, // Improve cross-browser support
    },
    };
    export const msalInstance = new PublicClientApplication(msalConfig);
