import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import {msalInstance } from 'src/config/msalConfig';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
      <MsalProvider instance={msalInstance}>
        <App />
        </MsalProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
